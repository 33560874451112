import { lazy, Suspense } from "react";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Footer from "../components/Footer";
import Header from "../components/Header";
import { Styles } from "../styles/styles";
import Home from "../pages/Home";
import Terms from "../pages/Terms";
import PrivacyPolicy from "../pages/PrivacyPolicy";
import RefundPolicy from "../pages/RefundPolicy";

const AppRouter = () => {
  return (
    <Suspense fallback={null}>
      <Styles />
      <Header />

          <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/privacypolicy" element={<PrivacyPolicy />} />
          <Route path="/refundpolicy" element={<RefundPolicy />} />    
          <Route path="/pay" element={<RefundPolicy />} />    
          
          </Routes>  
          
      <Footer />
    </Suspense>
  );
};

export default AppRouter;
