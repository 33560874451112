import { Row, Col } from "antd";
import { withTranslation, TFunction } from "react-i18next";
import { SvgIcon } from "../../common/SvgIcon";
import Container from "../../common/Container";

import i18n from "i18next";
import {
  FooterSection,
  Title,
  NavLink,
  Extra,
  LogoContainer,
  Para,
  Large,
  Chat,
  Empty,
  FooterContainer,
  Language,
  Label,
  LanguageSwitch,
  LanguageSwitchContainer,
} from "./styles";

interface SocialLinkProps {
  href: string;
  src: string;
}

const Footer = ({ t }: { t: TFunction }) => {
  const handleChange = (language: string) => {
    i18n.changeLanguage(language);
  };

  const SocialLink = ({ href, src }: SocialLinkProps) => {
    return (
      <a
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        key={src}
        aria-label={src}
      >
        <SvgIcon src={src} width="25px" height="25px" />
      </a>
    );
  };

  return (
    <>
      <FooterSection>
        <Container>
          <Row justify="space-between">

            <NavLink to="/">
              <LogoContainer>
                <SvgIcon
                  src="logo.svg"
                  aria-label="homepage"
                  width="101px"
                  height="64px"
                />
              </LogoContainer>
            </NavLink>

            <Col lg={5} md={6} sm={12} xs={12}>
              <Language>{t("Contact")}</Language>
              <Para  style={{margin: "5px 5px 5px 5px"}}>
                <a href="https://wa.me/9617088102" target="_blank" rel="noopener noreferrer">
                  <img
                    src="img/icons/whatsapp.png"
                    width="28px"
                    height="28px"
                  />
                </a>
                {t(`+919617088102`)}
              </Para>

              <Para style={{margin: "5px 5px 5px 5px"}}>
                <a target="_blank" rel="noopener noreferrer">
                  <img
                    src="img/icons/telephone.png"
                    width="28px"
                    height="28px"
                  />
                </a>
                {t(`+919617088102`)}
              </Para>
               <Para style={{margin: "5px 5px 5px 5px"}}>
                <a target="_blank" rel="noopener noreferrer">
                  <img
                    src="img/icons/gmail.png"
                    width="25px"
                    height="25px"
                  />
                </a>
                {t(`ssp.bhartiparmar@gmail.com`)}
              </Para>

            </Col>
            <Col lg={5} md={6} sm={12} xs={12}>
              <Title>{t("Policy & Terms")}</Title>
              <Large to="/privacypolicy">{t("Privacy policy")}</Large>
              <Large to="/refundpolicy">{t("Refund Policy")}</Large>
              <Large to="/terms">{t("Terms & conditions")}</Large>
            </Col>

            <Col lg={5} md={6} sm={12} xs={12}>
              <Language>{t("Address")}</Language>
              <Para>English pura, Sehore</Para>
              <Para>India</Para>
            </Col>
            <Col lg={5} md={6} sm={12} xs={12}>
              <Title>{t("Company")}</Title>
              <Para>SOLVEXATION TECHNOLOGIES PRIVATE LIMITED</Para>
              <Para>India</Para>
            </Col>


          </Row>

        </Container>
      </FooterSection>

    </>
  );
};

export default withTranslation()(Footer);
