import { lazy } from "react";
import {Content } from "./styles"



const Container = lazy(() => import("../../common/Container"));
const ScrollToTop = lazy(() => import("../../common/ScrollToTop"));
const ContentBlock = lazy(() => import("../../components/ContentBlock"));

//Solvexation Technologies Pvt ltd (Antalgo)’s
const Terms = () => {
  return (
    <Container>
      <ScrollToTop />
       <h6>Agreement / Terms of Use</h6>
       <h4>Agreement Introduction</h4> 
       <Content></Content>
       <Content>These terms of use (“Terms of Use”) provide the terms and conditions on which users (“You” or “Yourself” or “User”) access, register and subscribe on the website Antalgo and its sub-domains owned/managed
         and/or the mobile application Antalgo (collectively referred to as “the Platform”) owned and operated by Solvexation Technologies Pvt ltd (“Company” or “Us” or “We”) to avail the Services (defined below).
          Please note that the Privacy Policy is an integral part of the Terms of Use and should be read together. 
       </Content>
       <Content>These Terms of Use constitute a legally binding agreement between Solvexation Technologies Pvt ltd provider of Antalgo, "us" or "we" and you (“you”), the individual logging in with their email or mobile no. or client id, sets forth the terms and conditions that govern your use of Antalgo (the "Site" or “ Mobile App” Together referred as Platform or Antalgo) and/or any related services (the "Services") accessed through the Antalgo platform.
       </Content>
       <Content>We refer to these Terms of Use as the “Agreement.” We may update or amend these Terms of Use from time to time by posting such updates or amendments to the Site. Your use of the Site after we post any such updates or amendments will constitute your agreement to those updates or amendments.
       </Content>
       <Content>THIS AGREEMENT REQUIRES THE USE OF ARBITRATION ON AN INDIVIDUAL BASIS TO RESOLVE DISPUTES, RATHER THAN JURY TRIALS OR className ACTIONS, AND ALSO LIMITS THE REMEDIES AVAILABLE TO YOU IN THE EVENT OF A DISPUTE.
       </Content>
       <Content>PLEASE CAREFULLY READ THESE TERMS OF USE BEFORE ACCESSING THE SITE OR USING ANY OF THE SERVICES. IF YOU DO NOT WISH TO BE BOUND BY THESE TERMS AND CONDITIONS OR IF YOU CANNOT REMAIN IN COMPLIANCE WITH SUCH TERMS AND CONDITIONS, YOU MAY NOT ACCESS THE SITE OR USE ANY OF THE SERVICES AND SHOULD IMMEDIATELY CEASE SUCH ACCESS AND USE.
       </Content>
       <h4>Use of Services</h4>

       <Content>Unless otherwise specified, Solvexation Technologies Pvt ltd grants to you a non-exclusive and non-transferable limited right and license to access the Site Antalgo or the mobile android app. The users of this platform, for trading need to have opened a trading and demat account in any of the Broker(s) enabled on the platform. The Services provided on this platform (Antalgo) is for your personal use only, provided that you agree with and comply fully with the provisions of this Agreement. Certain features of the Site or Services are available only to users who register and subscribed to specific non-free plans and can differ between various devices. The complete customer facing features of Antalgo Platform may vary based on such non-free subscription plans described on Antalgo or may be published or modified in the future.
       </Content>
       <Content>You acknowledge and understand that we provide tools and infrastructure designed to allow you to create, import, export, and deploy your strategies and strategic trading strategies live in the market.
       </Content>

       <Content>To be clear, we  do not  provide any trading strategy or trading strategies, rather the tools to create them and the data with which you can test and use them. You can create your own strategies or use strategies made available on the Site to modify them as per your needs. 
       </Content>
       <h4>Confidentiality
       </h4>
       <Content>The information or other content that is available through the Service (other than information that is in the public domain that is included therein) and the Software are the confidential information of Solvexation Technologies Pvt ltd and embody valuable trade secrets of Solvexation Technologies Pvt ltd. You will not, directly or indirectly, disclose information regarding the Services or the Software, including the name of the Software tool, the features or benefits of the Software, screenshots or any other related content or information, to any third party in any media or in any manner, and will hold all such information in confidence, subject to any disclosure required by law, court order, or by any government or regulatory authority. You shall not make any public announcement, press release, or communication concerning this Agreement without the prior express written consent of Solvexation Technologies Pvt ltd.
       </Content>
       <h4>Accounts, Passwords and Security
       </h4> 
       <Content>To become a registered user, you can login to Antalgo by using your email/phone and client id. We are eligible to know your current, complete and accurate personal identifiable information, including, without limitation, your real name and the email address through which we can correspond with you and the telephone number, as prompted by the applicable registration form. You further agree to keep any registration information you provide to Solvexation Technologies Pvt ltd current, complete and accurate.
       </Content>
       <Content>FURTHERMORE, YOU ARE ENTIRELY RESPONSIBLE FOR ANY AND ALL ACTIVITIES AND CONDUCT, WHETHER BY YOU OR ANYONE ELSE, THAT ARE CONDUCTED THROUGH YOUR ACCOUNT. We may hold you liable for any losses incurred by Solvexation Technologies Pvt ltd. or any other party due to someone else’s use of your account or client id. You agree to notify Solvexation Technologies Pvt ltd. by writing to support@Antalgo immediately upon your becoming aware of any unauthorized use of your account or any other breach of security involving your account. Solvexation Technologies Pvt ltd will not be liable for any loss that you or any other party may incur as a result of someone else’s use of your password or account, either with or without your knowledge.
       </Content>
       <h4>Prohibited Activities
       </h4> 
       <Content>We use the term “Content” to mean entire or partial strategies, trading strategies, data transformations, data analysis and manipulation functions, tools, software, data, databases, text, messages, images, graphics, video files, audio files, ideas or any other information and materials. We use the term “Shared Content” to mean the Content (other than third party data) that we, you, or other Registered Users of Solvexation Technologies Pvt ltd post in publicly accessible areas of the Site and Services. Third party data is subject to the terms and conditions of the provider of such data. Other than as provided at the end of this Section in respect of Shared Content, you acknowledge and agree that you will NOT:
       </Content>
       <Content>i. Copy, modify, publish, transmit, distribute, transfer or sell, create derivative works of, or in any way exploit, any of the Content accessible through the Site not submitted or provided by you, including by use of any robot, spider, scraper, scripting, deep link or other similar automated data gathering or extraction tools, program, strategic or methodology, unless you obtain Solvexation Technologies Pvt ltd prior written consent; use the Site or Services to advertise, market, sell, or otherwise promote any commercial enterprise that you own, are employed by or are otherwise compensated by, either directly or indirectly; use any engine, software, tool, agent or other device or mechanism to navigate or search the Site, other than the search engines and agents available through the Service and other than generally available third party web browsers; copy, reverse engineer, reverse assemble, otherwise attempt to discover the source code, distribute, transmit, display, perform, reproduce, publish, license, create derivative works from, transfer or sell any information, software, products or services obtained through the Site or the Services; access the Site or use the Services by any means other than through Solvexation Technologies Pvt ltd - provided or approved interfaces; transmit any Content that is unlawful, harmful, threatening, abusive, harassing, tortious, defamatory, vulgar, obscene, libelous, or otherwise objectionable or which may invade another's right of privacy or publicity;
       </Content>

       <Content>ii. post or transmit any material that contains a virus, worm, Trojan horse, or any other contaminating or destructive feature; post or transmit information protected under any law, agreement or fiduciary relationship, including but not limited to proprietary or confidential information of others; use any of the Site’s or Service's communications features in a manner that adversely affects the availability of its resources to other users; post or transmit any unsolicited advertising, promotional materials, "junk mail", "spam," "chain letters," "pyramid schemes" or any other form of solicitation or any non-resumé information such as opinions or notices, commercial or otherwise; access or use the Site or Services to intentionally or unintentionally violate any applicable local, state, national or international law, including, but not limited to, regulations promulgated under any such law; upload or transmit any material that infringes, violates or misappropriate any patent, trademark, service mark, trade secret, copyright or other proprietary rights of any third party or violates a third party’s right of privacy or publicity; manipulate or otherwise display or obstruct portions of the Site and/or the Services by using framing or similar navigational technology; register, subscribe, attempt to register, attempt to subscribe, unsubscribe, or attempt to unsubscribe, any party for any Solvexation Technologies Pvt ltd product or Service if you are not expressly authorized by such party to do so;
       </Content>
       <Content>iii. use the Site and/or the Services for any purpose that is unlawful or prohibited by these terms and conditions; use the Site or the Services in any manner that could damage, disable, overburden or impair Solvexation Technologies Pvt ltd servers or networks, or interfere with any other user's use and enjoyment of the Site and/or the Services; attempt to gain unauthorized access to any of the Site, Services, accounts, computer systems or networks connected to Solvexation Technologies Pvt ltd through hacking, password mining, brute force or any other means; obtain or attempt to obtain any Content through any means not intentionally made available as Shared Content through the Site or the Services; or knowingly provide any Content that is false or inaccurate or will become false or inaccurate at any time.
       </Content>

       <Content>iv. use of any third party services/software/mechanisms/tool/plugins/code injections on Antalgo website/app or any other Antalgo services.
       </Content>
       <h4>Investment Disclaimer</h4>
       <Content>You acknowledge and understand that the Services of Solvexation Technologies Pvt ltd (Antalgo) are not intended to supply investment, financial, tax or legal advice. The Services are not investment advice and any observations concerning any security, trading strategy or investment strategy provided in the Services is not a recommendation to buy, sell or hold such investment or security or to make any other investment decisions. We offer no advice regarding the nature, potential value, risk or suitability of any particular investment strategy, trading strategy, transaction, security or investment. You acknowledge and agree that any use of the Services, any decisions made in reliance on the Services, including any trading or investment decisions or strategies, are made at your own risk. If investment, trading or other professional advice is required, the services of a competent, licensed professional should be sought. No employee, agent or representative of Solvexation Technologies Pvt ltd is authorized to provide any such advice pursuant to this Agreement, and any such advice, if given, is in violation of Solvexation Technologies Pvt ltd  policies, and unauthorized and hence should not be relied upon.
       </Content>

       <Content>You are Solely Responsible for Input, Correctness, and Accuracy. The quality of the product's analysis and optimization depends on the user's inputs. After deployment, alerts have been made available in the product to ease and expedite your entry of the position, and you, as user of the system, are solely responsible for ensuring the quality of all its inputs. As such, you must carefully review all input parameters in all ways necessary to ensure their accuracy and fidelity. While there are other factors governing analysis and optimization accuracy, the quality of the product outputs depends on the accuracy of your inputs. The trades generated by the Service may increase beyond what is practical to execute, due to broker execution limits and the difficulties in executing a complex trade in an all-or-none fashion. Moreover, once the trade is executed, the management of a complex trade becomes more difficult than is normally the case. Another factor is that you may not be authorized to execute all contract types found in the solutions generated. Solvexation Technologies Pvt ltd makes no representation that a solution generated by the Service can be executed and effectively monitored and managed in practice. It is entirely your responsibility to assess the appropriateness, suitability, and practicality of the solutions generated by the Antalgo Creator web based Software tools. It is your responsibility to ensure the trade is executable and manageable, and appropriate for your needs.
       </Content>
       <Content>
       By importing strategies from Strategy Creator, you acknowledge and agree that the quality and effectiveness of the imported strategies depend on the accuracy and completeness of the input parameters provided by Strategy Creator. It is your responsibility to review and verify all inputs to ensure their correctness. The strategies generated by Strategy Creator may involve complex trades that could be challenging to execute and manage. You are solely responsible for assessing the feasibility and practicality of executing these trades within your brokerage’s limits and your own trading capabilities. Ensure that you are authorized to execute all contract types included in the imported strategies. Solvexation Technologies Pvt Ltd makes no representation regarding your authorization to execute these trades. It is your responsibility to evaluate the risks associated with the imported strategies. Solvexation Technologies Pvt Ltd does not guarantee the performance or success of any strategy. Solvexation Technologies Pvt Ltd is not liable for any losses or damages arising from the use of imported strategies. You use these strategies at your own risk. Ensure that your use of the imported strategies complies with all applicable laws, regulations, and brokerage policies. By importing and using strategies from Strategy Creator, you agree to these terms and conditions.
       </Content>
      
       <Content>
       Indian laws require Portfolio Managers and Investment Advisory Service providers to be registered under SEBI. It is the responsibility of the strategy creator to ensure they are registered, if required, to conduct portfolio management business or advisory services. Investors are advised to verify the credentials of the portfolio managers or investment advisors before investing.

All due diligence before investing in any strategy is the responsibility of the user. Antalgo assumes no part in this process as it is a neutral technology service provider, facilitating the engagement of traders with portfolio managers and investment advisors, and does not provide financial services itself.
        </Content> 

       <h4>Cancellation of subscription</h4>
       <Content>Service Fee once paid is non-refundable. All prices are inclusive of taxes. The billing cycles are for three months (which we consider as 90 days), six months (which we consider as 180 days), one year (which we consider as 365 days) or five year(full life) (which we consider as 1825 days) from the first day of the billing cycle, and this subscription might or might not be auto-renewing subscription depending on the platform or the payment method you might be subscribing from, so you understand and are responsible for reading the details of the plan and its setting at the time of subscription.
       </Content>
       <Content>We bill on the first day of the billing cycle as soon as you subscribe. The billing cycle will get renewed for another cycle of the same period as the previous cycle automatically at the end of the previous cycle. In order to avoid getting charged for the next billing cycle, You need to cancel at least one day before the commencement of the next billing cycle. All prices charged are inclusive of taxes.
       </Content>
       <Content>You will be able to cancel the subscription to the Platform by clicking on "Cancel subscription" in Your billing section for web Antalgo platform.
       </Content>
       <Content>Once You cancel your subscription, You will no longer be able to use the Platform, excluding for the current billing cycle that You have paid for. For example, if You have subscribed on the 1st of Jan 2024, You will be able to use the platform till 30 Jan 2024 and the next billing date is 31 Jan 2024. If for any reason, You decide to cancel the subscription on 19 Jan 2024 You will be able to use the platform till 30 Jan 2024.
       </Content>
       <Content>Notwithstanding anything contained herein, Antalgo reserves the right to cancel any user (your) subscription anytime with or without prior notice to the client (you).
       </Content>
       <h4>Intellectual property and Trademark
       </h4>
       <Content>The Website Antalgo, its general structure, its create, and deploy features, as well as text, animated or non-animated images, know-how, drawings, graphics, as well as the look and feel of the site and the features of no coding, strategy trade without coding are and shall remain the exclusive property of Solvexation Technologies Pvt ltd. You are only entitled to view information for your private use only. You may not reproduce, by any means, or process, in whole or in part, distribute, publish, transmit, create derivative works based on, modify or sell any such materials contained on the site. Any total or partial representation of this site by any process without the express authorization of the operator of the Website is prohibited and legal action will be enforced punishable by law.
       </Content>
       <Content>You hereby agree that the Company shall be the owner of all Content created as a result of your use of the Platform or Services. To the extent that the law recognizes You as the owner of any right in any such data or information, You hereby agree that all such rights shall be assigned to the Company immediately upon creation except for your private data under the data privacy law applicable. To the extent such an assignment is not permitted under applicable law, You agree to provide a perpetual, assignable, exclusive and royalty-free license to enjoy all rights in the such Content owned by You. You agree to take all necessary actions as required by the Company to give effect to this clause.
       </Content>
       <Content>Antalgo is a trademarked word in India by Solvexation Technologies Pvt ltd.
       </Content>
       <Content>The trademarks, logos and service marks displayed on the Platform (“Marks”) are the property of the Company or a third party. You are not permitted to use the Marks without the prior consent of the Company or the third party that may own the Marks. 
       </Content>
       <h4>Risk Disclosure Statement</h4>
       <h4>INTRODUCTION</h4>
       
       <Content></Content>
<Content>Welcome to Antalgo, a trading platform. Antalgo is provided by Solvexation Technologies Pvt ltd, a web and app based platform to create, import, export, and deploy trading strategies.
</Content>
<Content>Create is a process to input entry and exit conditions involving technical indicators and rules, stop loss percentage, take profit percentage, instrument, quantity of stocks etc and form a trading strategy, hereafter referred to as "Bot" or "Trading strategy" or "strategies".
</Content>
{/* <Content>Backtesting is the process of testing a trading strategy on relevant historical data to ensure its viability before the trader risks any actual capital. A trader can simulate the trading of a strategy over an appropriate period of time and analyze the results for the levels of profitability and risk. Solvexation Technologies Pvt ltd (Antalgo) strives to give you accurate information on your trading strategies and your strategies, however methodology covers the PROCESS AND ASSUMPTIONS THAT ARE MADE DURING CALCULATING THE RESULTS OF THE TRADEBOT(STRATEGY) DURING BACKTEST AND THE DATA SHOWN AFTER DEPLOYMENT.
</Content> */}
<Content>In addition to the services that allow you to create a TradeBot (strategies), Antalgo is a trading platform that allows you to engage in live trading through add to live and apply features in the platform.
</Content>
<Content>Using Solvexation Technologies Pvt ltd (Antalgo)’s Services, including its deploy services, presents several different types of risk. We have summarized these below. You should read and understand these risks before you use any of Solvexation Technologies Pvt ltd (Antalgo)’s services.
</Content>
<h4>RISKS</h4>
<Content>Any use of third party services or softwares on Antalgo to avail additional functionality, may cause your Antalgo platform to malfunction, alter expected outcomes and cause problems. As per our terms of use, we strongly suggest to avoid usage of any third party softwares or plugins along with Antalgo platform (website and mobile applications).
</Content>
<Content>The risks that can arise from using Solvexation Technologies Pvt ltd (Antalgo)’s Site and Services fall into three broad categories:
</Content>
<Content>1. Risks inherent generally in using Internet-based technology;
</Content>
<Content>2. Risks inherent in creating, importing, and deploying trading strategies; and
</Content>
<Content>3. Risks inherent in engaging in live strategic trading.
</Content>
<Content>ANY PERMUTATION OR COMBINATION OF THE OCCURRENCE OF THE POTENTIAL EVENTS THAT DEFINE THE RISKS DESCRIBED IN THIS DISCLOSURE STATEMENTS CAN LEAD TO A TOTAL OR PARTIAL LOSS OF OPERABILITY, RESPONSIVENESS, FUNCTIONALITY, AND FEATURES THAT COULD MATERIALLY AND ADVERSELY AFFECT YOUR USE OF Antalgo OR ANY PLATFORM OR DOMAIN SERVED BY US.
</Content>
<h4>Risks of Using Internet-based Technology - Generally
</h4>
<Content>The Internet-related technological risks arising from using Solvexation Technologies Pvt ltd(Antalgo)’s Site and Services to write, test, analyze, and run trading strategies and related trading strategies fall into three categories: (a) risks related to Solvexation Technologies Pvt ltd (Antalgo)’s software; (b) risks related to Solvexation Technologies Pvt Ltd (Antalgo)’s computing and communications infrastructure; and (c) risks related to your software, hardware, and Internet connectivity. It is your obligation to thoroughly and appropriately test any trading strategy before you deploy it and to continually monitor the operation of any deployed trading strategy to ensure it is running properly and in compliance with any applicable rules.
</Content>
<Content>a. Solvexation Technologies Pvt ltd  (Antalgo)’s software/code might fail to work properly.
</Content>

<Content>i. All software is subject to inadvertent programming errors and bugs embedded in the code comprising that software. Any of these errors and bugs can cause the software in which they are located to fail or not work properly. The applications software used to operate Solvexation Technologies Pvt ltd (Antalgo)’s Site and Services it depends on is subject to this risk. Despite testing and on-going monitoring and maintenance, inadvertent errors and bugs may still cause a failure in Solvexation Technologies Pvt ltd (Antalgo)’s applications software.
</Content>
<Content>ii. We may update or revise our applications software in ways that cause some of its functionality or features to be lost or diminished. Any such loss or diminution could make Solvexation Technologies Pvt ltd (Antalgo) less valuable to you, cause certain functions and features in your strategies to fail, and require you to change your strategies and related trading strategies.
</Content>
<Content>b.Solvexation Technologies Pvt ltd (Antalgo)’s computing and communications infrastructure may fail.
The operation of Solvexation Technologies Pvt ltd (Antalgo)’s Site and Services depend heavily on our infrastructure of computing and communications systems. The operation of this infrastructure is subject to several risks:
</Content>
<Content>i. Any or all of the systems comprising our infrastructure could entirely or partially fail, function erratically, or function very slowly (thereby leading to latency, i.e., delays in receipt of and response to user requests).
</Content>
<Content>ii. We may inadvertently cause a systems failure during planned or unplanned system maintenance.
</Content>
<Content>iii. We may undertake software upgrades, either planned or unplanned, that take longer to implement or that causes your computer system or Internet connectivity to fail.
</Content>
<Content>iv. We may change or remove functions and features whose change or removal causes your system to fail, function erratically, or function very slowly.
</Content>
<Content>c. Your computer system and your Internet connectivity may fail.
</Content>
<Content>Any of the components of your computer system and/or your Internet connectivity could fail entirely, function erratically, or function very slowly. The result of any of these occurrences could make it difficult or impossible for you to access the Solvexation Technologies Pvt ltd(Antalgo) Site or use the Solvexation Technologies Pvt ltd (Antalgo) Services.
</Content>
<Content>You may incur losses (or fail to gain profits) while trading securities. You should discuss the risks of trading with the broker-dealer where you maintain an account or other investment professional. Solvexation Technologies Pvt ltd (Antalgo) provides you only with trading technology and can provide no investment, financial, regulatory, tax or legal advice.
</Content>
<Content>Risks in Create, import, and Deploying Strategies (strategies)
</Content>
<Content>Creating, import, and deploying computer-based trading strategies is subject to several risks, any of which can cause your strategies to not function as you had intended or fail to achieve one or more of the objectives of your strategies. strategic trading is rapidly changing as a practice and as an industry. Models of markets used to write and test trading strategies are inherently limited and often fail to perform as expected. In addition, trading strategies are implemented in software programming code, and no matter how well designed and thoroughly tested, any such code can have logical errors and bugs that cause the strategies to malfunction or suggest trades that, if executed, would result in losses. It is your obligation to thoroughly and appropriately test any trading strategic before you deploy it and to continually monitor the operation of any deployed trading strategic to ensure it is running properly and in compliance with any applicable rules
</Content>
<Content>a. Your strategy may be designed on the basis of an incorrect understanding of technical indicators which may not work as expected.
</Content>
<Content>b. Your strategy may contain logical errors in the way you understand the indicators and comparators.
</Content>
<Content>c. Errors may exist in the data used for testing your strategic or the applicable model of the market.
</Content>
<Content>d. Your strategy might appear to succeed in a backtesting environment using historical data, but fail when using live data.
</Content>
<Content>e. Your strategy might appear to succeed with some data sources, but fail when using other data sources in our system.
</Content>
<Content>f. Your strategy may not achieve the returns you anticipate. There are no guarantees, or even expectations, that can be made about the future behavior of a strategy.
</Content>
<Content>g. Import strategy from unknown or unauthorized person.
</Content>

<Content>Risks of Engaging in Live Strategic Trading and Related Strategies
</Content>
<Content>Solvexation Technologies Pvt ltd (Antalgo) and broker allows you to engage in live trading. Engaging in live trading subjects you to (a) the risks associated with trading generally, and (b) the risks associated with live strategic trading using Solvexation Technologies Pvt ltd (Antalgo).
</Content>

<Content>THE OCCURRENCE OF ANY OF THE EVENTS ASSOCIATED WITH THESE RISKS, ALONE OR IN COMBINATION WITH ANY OF THE OTHER RISKS DESCRIBED IN THIS DISCLOSURE STATEMENT, COULD RESULT IN THE LOSS OF ALL OF THE MONEY YOU HAVE DEPOSITED IN THE BROKERAGE ACCOUNT YOU USE FOR LIVE TRADING BASED ON THE strategies YOU WRITE, TEST, AND RUN ON Solvexation TechnologiesPvt Ltd (Antalgo). LOSSES CAN HAPPEN MORE QUICKLY WHEN USING STRATEGIC TRADING THAN OTHER FORMS OF TRADING. YOU SHOULD DISCUSS WITH AN INVESTMENT PROFESSIONAL THE RISKS OF TRADING IN GENERAL AND STRATEGIC TRADING IN PARTICULAR. YOU USE ANY STRATEGY IN LIVE TRADING AT YOUR OWN RISK AND IT IS YOUR OBLIGATION TO THOROUGHLY AND APPROPRIATELY TEST ANY TRADING STRATEGY BEFORE YOU DEPLOY IT AND TO CONTINUALLY MONITOR THE OPERATION OF ANY DEPLOYED TRADING STRATEGY TO ENSURE IT IS RUNNING PROPERLY AND IN COMPLIANCE WITH ANY APPLICABLE RULES.
</Content>

<Content>Certain Risks of Live Strategic Trading
</Content>
<Content>In addition to all of the risks described above, live strategic trading is subject to the following types of types of risk:
</Content>
<Content>i. Paper trades cannot Assure Actual Results.
</Content>
<Content>It is not possible for a computer model to truly predict what might have happened if a strategic-based trading strategy was in play in a live trading environment. For example, the implementation of such a strategy can itself have an impact on the market, and the model may fail to account for real-life factors that impact the model. Moreover, the model may fail to account for execution costs including broker commissions, fees, and trading slippage.
</Content>
<Content>A promising model result does not necessarily predict a successful strategy. Execution of the strategy and the performance of that code may prove to be impossible in a live trading environment. Changes in various market factors not foreseen in a model can change, causing a strategy to fail. A backtest might be over-fitted to past data, and fail when the strategy is applied to new, live data. Orders that were executed correctly in the backtesting environment may be disallowed or rejected because of various reasons eg: margin requirement , illiquid stocks etc causing the strategy to fail or otherwise not perform as expected. Attempts to create, exit, or cancel orders might fail, or might result in unexpected outcomes. Moreover, your strategy might not handle market conditions that cannot be reasonably anticipated, i.e., a “flash crash” or an exchange outage. These market conditions, by definition, will not have been tested.
</Content>
<Content>ii. The relevant market might fail or behave unexpectedly.
</Content>
<Content>Market centers in which you seeking to implement your trading strategy may fail or behave incorrectly because of technical reasons relating to infrastructure, connectivity, and similar factors.
</Content>
<Content>Your strategy might suffer from adverse market conditions. Those conditions can include lack of liquidity, and abrupt and unwarranted price swings. Also possible are late market openings, early market closings, market chaos, and mid-day trading pauses, and other such disruptive events.
</Content>
<Content>iii. Your broker may experience failures in its infrastructure, fail to execute your orders in a correct or timely fashion or reject your orders.
</Content>
<Content>Antalgo infrastructure on which you are running your strategy might fail. In addition, even if Antalgo infrastructure or your broker's infrastructure and API are working correctly, the orders may get rejected in error or by design, incorrectly execute orders, or induce errors through unexpected behavior (such as returning messages out of sequence, incorrectly acknowledging orders, or posting incorrect execution reports). If at all, any losses arise from these risks, Solvexation Technologies Pvt ltd (Antalgo) bears no responsibility for this.
</Content>
<Content>iv. The system you use for generating trading orders, communicating those orders to your broker, and receiving queries and trading results from your broker may fail or not function in a correct or timely manner.
</Content>
<Content>Latency (i.e., delays) within and between your system, as well as those of your broker and the market in which you seek to affect trades, might cause orders, corrections, and cancels to be placed or not placed in ways that are not desired. You may receive incorrect information, or be unable to get information, about your orders, your positions, or market conditions. Incorrect actions may be taken, or correct actions may not be taken, because of inaccurate or missing information. In addition, you may be unable to terminate or edit your strategy.
</Content>
<Content>v. Time lag at various point in live trading might cause unexpected behavior.
</Content>
<Content>The time lag between the actionable alert generation time, the time at which you receive the actionable alert, and the time at which you take action on the alert, can cause a delayed order placement in the market, lead to cancellations, and cause you loss (or fail to gain profits) and may deliver unanticipated results vastly different from the backtest results.
</Content>
<Content>vi. The systems of third parties in addition to those of the provider from which we obtain various services, your broker, and the applicable securities market may fail or malfunction.
</Content>
<Content>Strategic trading depends on the availability of various services from third parties in addition to your service provider and your broker. These, for example, include providers of data services, computational services, and network connectivity. The operations of these third parties are beyond all of our reasonable control. Regardless of the reason for any failure by your broker, the market in which you seek to have trades executed, or these other third parties, we will not have any liability for any such failure.
</Content>
<Content>Accurate and complete real-time price data is critical for the success of strategic trading. The systems of these data providers could experience failures, errors, and latency, which could result in missing, incorrect, or stale market data.
</Content>
<Content>vii. Malicious and criminal activities might cause your strategies and strategy to fail or your brokerage account to be compromised.
</Content>
<Content>All computers and networks are subject to malicious “hacking” attacks and criminal activities designed to misappropriate intellectual property, compromise personally identifiable information, steal funds, or any combination of such purposes. These attacks might be attacks on a target of opportunity or specifically targeted. Each of the various systems described above that are necessary for you to engage in live strategic trading is subject to such attack. Any such attack could cause the system so attacked to function improperly or not at all and could result in the misappropriation of your intellectual property, the compromise of your personally identifiable information and personal financial information, the theft of your funds and can cause your strategy be misbehave, malfunction or behave erratically.
</Content>

<h4>DISCLAIMERS</h4>

<h4>Browser Notification</h4>
<Content>The first time you login to Antalgo website, the web browser asks your permission to allow browser notification. Please allow the notification, else you won't be getting any alerts on your system when the strategy condition is met.
</Content>
<h4>Sample/Test Strategies</h4>
<Content>Users can check the sample/Test strategies provided on the dashboard to understand the working of indicators and rules and how to use them. Antalgo is not responsible for any profits/losses occurred after deploying sample/test strategies in the market.
</Content>
<h4>Strategy alerts</h4>

<Content>Once the strategy is live and an alert is generated based on the conditions in the strategy, our systems attempt to deliver the alert to the user over the internet. By using this service, the user acknowledges they understand that the alerts' delivery is dependent on many factors such as the internet connection of the user, location, time of the day, server load, data availability etc.
</Content>
<Content>We recommend users to be logged in to Antalgo, keep it open in their browser and maintain a fast uninterrupted internet connection to their devices to see the best alerts delivery.
</Content>
<Content>Antalgo relies on  third party services for market data eg. for ticks, OHLCV etc.  If these services are down due to unforeseen circumstances or experience a down time due to various technical / non technical issues, Antalgo might not be able to generate and deliver the actionable alerts on time or at all. Market prices, data and other information available through Antalgo are not warranted as to completeness or accuracy and are subject to change without notice. System response and account access times may vary due to a variety of factors, including trading volumes, market conditions, system performance, and other factors.
</Content>
<Content>Once a signal is generated, we try to send this signal to the user's device over the internet. The delivery of these alerts are subject to network conditions of the user, internet services and technical issues.
</Content>
<Content>Accurate and complete real-time price data is critical for the success of strategic trading. Our service providers or systems that provide data could experience failures, errors, lag, and latency which could result in missing, incorrect, or stale market data leading to no/wrong signals(alert) while triggering an alert.
</Content>
<Content>All actionable order alerts are read-only market order alerts, where with a single click the user can send the order to the exchange. The actionable order alerts are made read-only in order to obtain consistency in the deployed and backtest results and to avoid any drastic increase in risk. If on any scrip / instruments such as Stock, Futures, Currency Futures etc., there is high volatility due to news based or non news based or any speculative events / positions, Antalgo is not responsible for higher slippages. You understand that volatility is the nature of the market.
</Content>
<Content>Upon clicking on buy/sell on the order window, based on users network speed, a network latency can be experienced and any rapid clicks on the buy/sell button through same or different windows can lead to multiple order placements. Users take full responsibility for making sure the actions on the notifications are their own actions and are fully aware of their positions and strategy status when clicking on the buy/sell button.
</Content>
<h4>Strategy deployment cycle / Strategy Cycle
</h4>
<Content>The strategy cycle  Entry and respective exit of a strategy is defined as a strategy cycle.
</Content>
<Content>Once a strategy is deployed, the stocks are periodically tracked based on the conditions in the strategy. The periodicity with which the market is tracked is the same as the candle interval selected by the user while backtesting the strategy and shown in the strategy summary before deployment. An strategy's ideal life cycle and the tree of events that can occur during an strategy's life cycle, called an "strategy cycle", has been explained below:
</Content>
<Content>1. Waits for the first entry event as per the entry condition in the deployed section.
</Content>
<Content>2. Once the entry event occurs, an entry signal (buy/sell) is triggered and an actionable alert is sent to the user.
</Content>
<Content>3. The user can choose to act on the alert by clicking buy/sell or choose to ignore the alert and cancel it (cancelling from the notification window will terminate the strategy).
</Content>

<Content>4. If the user has clicked on buy/sell in the alert, a market order is sent to the exchange (NSE).
</Content>

<Content>5. The order will either be successfully placed by the exchange or it might get rejected due to reasons such as insufficient capital, etc. If the order gets rejected by the exchange, the strategy gets terminated. Antalgo does not verify the margin requirement, the broker determines the margin requirement as all orders are placed on the broker.
</Content>

<Content>6. After the successful placement of an order, the strategy immediately triggers an SL-M order. This is a STOP LOSS order which the user can place or cancel. Canceling this order will NOT terminate the strategy and your positions will be open and if your Stop Loss is triggered later , then a notification is sent again to the user. (If you have already placed the SL-M order then if the SL hits first then this order will get executed and the position is closed).
</Content>

<Content>7. The strategy continues to track the stock waiting for the exit signal (SL or TP) or exit condition. Based on the entry price and the SL and TP percentages entered by the user, the SL and TP prices are calculated which are displayed to the user in the Deployed Page Subsection ENTERED.
</Content>

<Content>8. When the SL/TP price occurs or if the exit condition is met, whichever occurs first, an actionable notification is sent to the user again. The user can then choose to act on it by clicking buy/sell or choose to ignore the alert and cancel it (cancel will terminate the strategy).  Note:  If you cancel the SL or TP alert from the notification/alerts page the strategy gets terminated. However, if you cancel the notification or alert from the order log in the deployed page, the strategy is not terminated.
</Content>

<Content>9. If the user has clicked on buy/sell in the alert, a market order is sent to the exchange (NSE) and the strategy's life cycle is now complete.
</Content>

<Content>10. The "strategy cycle" sequence defined above is an ideal sequence and is subject to market conditions and user behaviour. Based on the user's action or market conditions, the sequence might not completely occur in the same way as it is intended to, since this sequence may have been interrupted due to various reasons such as, the user stopping the strategy, order rejection by the exchange, network lag, network error etc.
</Content>

<Content>11. For both intraday and overnight strategies, the strategy cycle has to be defined by the user during deployment. Depending on the number of strategy cycles, the strategy moves to a 'complete' state and is stopped.
</Content>

<Content>12. Margins are not blocked till the user acts on the actionable alerts (buy/sell) and the order is sent to the exchange.
</Content>

<Content>13.The strategy can be stopped by the user at any time in the life cycle of the strategy by clicking on the "Stop" button. If the strategy has not entered a position, it will be directly stopped otherwise the user will be presented with an option to either stop the strategy by keeping the positions open or to exit positions at market and stop the strategy. The strategy will no longer be tracked and no further alerts will be sent to the user for the respective strategy.
</Content>

<Content>14. In cases where the user stops the strategy and chooses to keep positions open, the responsibility of closing any and all positions is solely on the user, and user will get no alerts for that deployed instrument once the strategy is stopped.  
</Content>

<Content>15. For order type MIS, all strategies will be stopped at 3:20 PM (4:30 PM for currency futures, 11:30pm for MCX) and the open positions, if any, will be squared off by the respective broker (example, Angel One or 5paisa) before market close and charges for closure by broker is applied
</Content>

<Content>16. All actionable order alerts sent to the user can be used only once and will be active for only 5 minutes after which the alert expires in the notification/ alerts section. However, these alerts are actionable in the order log section of the Deployed Page.
</Content>

<Content>17. All actionable alert orders are market orders and users can expect price variation from the price at which the alert was triggered to the price at which the order is placed. Slippages are expected to occur.
</Content>

<Content>18. If the user's order is rejected due to various reasons such as shortage of funds, circuit limit hit, no liquidity in market, etc, the strategy will be stopped and no further alerts will be sent for that strategy. This is done in order to avoid unnecessary tracking of instruments where the order placement failed. However, the user can deploy the strategy again and take action on any new alerts that get generated.
</Content>

       
    </Container>
  );
};

export default Terms;