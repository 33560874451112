import styled from "styled-components";
import { Link } from "react-router-dom";

export const Title = styled("h4")`
  font-size: 22px;
  text-transform: capitalize;
  color: #18216d;

  @media screen and (max-width: 414px) {
    padding: 1.5rem 0;
  }
`;

export const Empty = styled("div")`
  position: relative;
  height: 53px;
`;

export const Content = styled("p")`
  font-size: 16px;
  color: #535252;
`;
