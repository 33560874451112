import { lazy } from "react";
import IntroContent from "../../content/IntroContent.json";
import algobenefits from "../../content/algobenefits.json";
import ProductContent from "../../content/ProductContent.json";
import ContactContent from "../../content/ContactContent.json";
import PriceContent from "../../content/pricing.json";
import FeatureContent from "../../content/features.json";
import DemoContent from "../../content/demo.json";
import FaqContent from "../../content/faq.json";



const Contact = lazy(() => import("../../components/ContactForm"));
const Container = lazy(() => import("../../common/Container"));
const ScrollToTop = lazy(() => import("../../common/ScrollToTop"));
const ContentBlock = lazy(() => import("../../components/ContentBlock"));
const PriceBlock = lazy(() => import("../../components/pricing"));
const FeatureBlock = lazy(() => import("../../components/features"));
const AlgoBenefitsBlock = lazy(() => import("../../components/algobenefits"));
const DemoBlock = lazy(() => import("../../components/demo"));
const FaqBlock = lazy(() => import("../../components/faq"));


const Home = () => {
  return (
    <Container>
      <ScrollToTop />
      
      <ContentBlock
        direction="right"
        title={IntroContent.title}
        content={IntroContent.text}
        button={IntroContent.button}
        icon="trader.svg"
        id="intro"
      />
       <DemoBlock
        direction="left"
        title={DemoContent.title}
        content={DemoContent.text}
        button={DemoContent.button}
        icon="trydemo.svg"
        id="demo"
      />
      <AlgoBenefitsBlock
        direction="right"
        title={algobenefits.title}
        content={algobenefits.text}
        section={algobenefits.section}
        icon="why.svg"
        id="n"
      />

      <PriceBlock
        direction="left"
        title={PriceContent.title}
        content={PriceContent.text}
        section={PriceContent.section}
        id="plan"
      />
      <FeatureBlock
        direction="right"
        icon={FeatureContent.icon}
        title={FeatureContent.title}
        content={FeatureContent.text}
        section={FeatureContent.section}
        id="feature"
      />
      
    
      <FaqBlock
        direction="left"
        title={FaqContent.title}
        content={FaqContent.text}
        faq={FaqContent.faq}
        id="product"
      />
      <Contact
        title={ContactContent.title}
        content={ContactContent.text}
        id="contact"
      />
    </Container>
  );
};

export default Home;
