import { lazy } from "react";
import {Content } from "./styles"

const Container = lazy(() => import("../../common/Container"));
const ScrollToTop = lazy(() => import("../../common/ScrollToTop"));
const ContentBlock = lazy(() => import("../../components/ContentBlock"));

const RefundPolicy = () => {
  return (
    <Container>
      <ScrollToTop />
      <h6>Refund & Cancellation Policy</h6> 
      <h4>Cancellation of Subscription or Plan</h4>    
      <Content>
        Service Fee once paid is non-refundable. All prices are inclusive of taxes. The billing cycles are for three months (which we consider as 90 days), six months (which we consider as 180 days) or one year (which we consider as 365 days) from the first day of the billing cycle, and this subscription might or might not be auto-renewing subscription depending on the platform or the payment method you might be subscribing from, so you understand and are responsible for reading the details of the plan and its setting at the time of subscription.
        </Content>
      
        <Content>
      
       We bill on the first day of the billing cycle as soon as you subscribe. The billing cycle will get renewed for another cycle of the same period as the previous cycle automatically at the end of the previous cycle. In order to avoid getting charged for the next billing cycle, You need to cancel at least one day before the commencement of the next billing cycle. All prices charged are inclusive of taxes.
       </Content>
       {/* <Content>
      
       You will be able to cancel the subscription to the Platform by clicking on "Cancel subscription" in Your billing section for web antalgo platform.
       </Content> */}
       <Content>
      
       Once You cancel your subscription, You will no longer be able to use the Platform, excluding for the current billing cycle that You have paid for. For example, if You have subscribed on the 1st of Jan 2024, You will be able to use the platform till 30 Jan 2024 and the next billing date is 31 Jan 2024. If for any reason, You decide to cancel the subscription on 19 Jan 2024 You will be able to use the platform till 30 Jan 2024.
       </Content>
       <Content>
      
       Notwithstanding anything contained herein, SpeedBot reserves the right to cancel any user (your) subscription anytime with or without prior notice to the client (you).   
       </Content>
       <Content>
      
       If you have any questions or concerns regarding our refund policy, please reach out to us at contact@speedbot.tech.
       </Content>
       <Content>
      
       By using antalgo.in, you agree to comply with this refund policy.
      </Content>
       
    </Container>
  );
};

export default RefundPolicy;
